@tailwind components;
@tailwind utilities;

:root {
  --min-width: 320px;
  --max-width: 1920px;
  --max-width-container: 1200px;
  --primary-font-family: 'Roboto', sans-serif;
  --text-color: #1d1d1f;
  --accent-color: #db042d;
  --header-height: 80px;
  --header-height: 80px;
}

* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

html,
body {
  height: 100%;
  min-width: var(--min-width);
}

body {
  font-family: var(--primary-font-family);
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
  font-family: var(--primary-font-family);
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/myriadpro-light.woff2') format('woff2');
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/myriadpro-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/myriadpro-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/myriadpro-semi.woff2') format('woff2');
}

.spoilers-block__item {
  border-bottom: 1px solid #909090;
}

.spoilers-block__item:first-child {
  border-top: 1px solid #909090;
}

.spoilers-block__item:last-child {
  margin: 0;
}

.spoilers-block__title {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #282828;
  cursor: pointer;
  padding: 40px 70px 40px 0;
  position: relative;
}

.spoilers-block__title::before {
  content: '';
  width: 20px;
  background-color: #282828;
  height: 2px;
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  -webkit-transform: translate(0%, 0%) rotate(-45deg);
  -ms-transform: translate(0%, 0%) rotate(-45deg);
  transform: translate(0%, 0%) rotate(-45deg);
  position: absolute;
  right: 15px;
  top: 50%;
}

.spoilers-block__title::after {
  content: '';
  width: 20px;
  background-color: #282828;
  height: 2px;
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  -o-transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  -webkit-transform: translate(30%, 0) rotate(45deg);
  -ms-transform: translate(30%, 0) rotate(45deg);
  transform: translate(30%, 0) rotate(45deg);
  position: absolute;
  right: 34px;
  top: 50%;
}

details > summary {
  list-style: none;
}

.spoilers-block__title::before {
  content: '';
  width: 20px;
  background-color: #282828;
  height: 2px;
  transform: translate(0) rotate(-45deg);
  position: absolute;
  right: 10px;
  top: 50%;
}

.spoilers-block__title::after {
  content: '';
  width: 20px;
  background-color: #282828;
  height: 2px;
  transform: translate(48%) rotate(45deg);
  position: absolute;
  right: 31px;
  top: 50%;
}
.spoilers-block__item[open] summary::after {
  transform: translate(48%) rotate(135deg);
}
.spoilers-block__item[open] summary::before {
  transform: translate(0) rotate(-135deg);
}

.spoilers-block__body {
  font-family: 'Inter', sans-serif;
  margin-bottom: 40px;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  max-width: 840px;
}

body {
  color: var(--text-color);
  font-size: 16px;
  font-family: 'myriadpro', sans-serif;
}

body._lock {
  overflow: hidden;
}

section {
  margin: 200px 0 0 0;
}

section:first-child {
  margin: 0;
}

.button {
  color: #222020;
  background: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: 14px;
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  cursor: pointer;
  font-weight: 400;
}

.button:hover {
  color: #fff;
  background-color: #222020;
}

.wrapper {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.hero-container {
  padding: 0 75px;
  position: relative;
  max-width: 1800px;
  margin: auto;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.small-container {
  max-width: 700px;
  margin: 0 auto;
}

.footer-container {
  padding: 0 200px;
  max-width: 1850px;
  margin: auto;
}

h2 {
  font-weight: 700;
  font-size: 41px;
  line-height: 120%;
  color: #222020;
  text-align: center;
}

.header {
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 15px;
  align-items: center;
  font-family: Inter, sans-serif;
  padding-bottom: 30px !important;
  max-width: 1650px;
  margin: auto;
  width: 100%;
  padding-top: 20px;
}

.header h2 {
  margin-bottom: 0;
  font-size: 38px !important;
}

.header__help a,
.header__help p {
  margin-bottom: 0;
  text-align: right;
  display: block;
  font-size: 14px;
}

.header__help a {
  color: #484848;
  font-weight: 700;
  text-decoration: underline;
}

.header__help {
  padding-right: 34px;
}

.header img {
  text-align: center;
  max-width: 240px;
  padding-left: 34px;
}

@media (max-width: 992px) {
  .header img {
    margin: auto;
  }
}

.header__menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 3;
  margin-bottom: 0px;
}

.header__menu li {
  margin-right: 50px;
  padding: 10px 0;
}

.header__menu li:last-child {
  margin: 0;
}

.header__menu > li {
  position: relative;
}

.header__menu a {
  color: #222020;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
}

.header__menu a:after {
  display: block;
  content: '';
  height: 1px;
  width: 0%;
  background-color: #222020;
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
}

.header__menu a:hover:after {
  width: 100%;
}

.header__logo {
  max-width: 200px;
}

.header .button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
}

.header .button-wrapper img {
  max-width: 120px;
}

.icon-menu {
  display: none;
}

:root {
  --min-width: 320px;
  --max-width: 1920px;
  --max-width-container: 1200px;
  --primary-font-family: 'Roboto', sans-serif;
  --text-color: #1d1d1f;
  --accent-color: #db042d;
  --header-height: 80px;
  --header-height: 80px;
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/myriadpro-light.woff2') format('woff2');
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/myriadpro-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/myriadpro-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'myriadpro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/myriadpro-semi.woff2') format('woff2');
}

.footer {
  margin-top: 200px;
  background: #222020;
}

.footer__menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 200px;
  padding: 80px 0;
  border-bottom: 1px #9f9f9f solid;
}

.footer__item b {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;
  color: #fff;
}

.footer__item p {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #fff;
}

.footer__item ul {
  margin-top: 40px;
}

.footer__item ul a,
.footer__item ul button {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer__item ul a:hover,
.footer__item ul button:hover {
  color: #2e358c;
}

.footer__item ul a li {
  margin-bottom: 15px;
}

.footer__item img {
  margin: 20px 0;
  max-width: 180px;
}

.footer__copyright {
  padding-bottom: 80px;
}

.footer__copyright p {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #9f9f9f;
  margin-bottom: 15px;
}

.footer__copyright p:first-child {
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  margin-bottom: 30px;
  margin: 20px 0 30px 0;
}

.footer__copyright p:last-child {
  margin: 0;
}

.footer__copyright p b {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #fff;
  margin-right: 5px;
}

.page {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.hero-section__content {
  background: #2e358c;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  padding: 45px 0 0 125px;
  max-height: 420px;
}

.hero-section__content h1 {
  font-family: 'myriadpro', sans-serif;
  color: #fff;
  font-weight: 700;
  font-size: 41px;
  line-height: 120%;
}

.hero-section__content p {
  font-family: 'myriadpro', sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin: 25px 0 40px 0;
}

.hero-section__content img {
  position: relative;
  top: -5%;
}

.hero-section__download-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 13px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px #9f9f9f solid;
  border-radius: 5px;
  max-width: 200px;
  position: absolute;
  padding: 7px 13px;
  background-color: #fff;
  bottom: -20%;
}

.hero-section__download-button img {
  width: 23px;
}

.hero-section__download-button p {
  color: #9f9f9f;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 0;
}

.hero-section__download-button span {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #222020;
}

.grid-section {
  margin-top: 281px;
}

.grid-section__content {
  margin-top: 60px;
}

.grid-section__content h2 {
  margin-bottom: 60px;
}

.grid-section__grid {
  padding: 0 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}

.grid-section__item {
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.grid-section__item img {
  width: 40px;
  margin: auto;
}

.grid-section__item b {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #222020;
  margin: 30px 0 25px 0;
}

.grid-section__item p {
  color: #9f9f9f;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
}

.mac-section h2 {
  max-width: 600px;
  margin: 0 auto 100px auto;
}

.mac-section__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 150px;
}

.mac-section__right b {
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #222020;
}

.mac-section__right p {
  margin-top: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #000;
}

.option-section h2 {
  max-width: 600px;
  text-align: center;
  margin: 0 auto 80px auto;
}

.option-section__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 128px;
  margin-bottom: 70px;
}

.option-section__item:last-child {
  margin-bottom: 0;
}

.option-section__item img {
  max-width: 150px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.option-section__left b {
  font-weight: 600;
  font-size: 26px;
  line-height: 120%;
  color: #222020;
}

.option-section__left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  margin-top: 40px;
  max-width: 420px;
}

.blue-section {
  background: #2e358c;
  padding: 60px 0;
}

.blue-section__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.blue-section h2 {
  max-width: 600px;
  margin: 0 auto 60px auto;
  color: #fff;
}

.blue-section__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px #fff solid;
  border-radius: 12px;
  padding: 64px 45px 64px 40px;
}

.blue-section__item img {
  width: 40px;
}

.blue-section__item b {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: #fff;
  margin: 20px 0 15px 0;
}

.blue-section__item p {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #fff;
}

.spoller-section h2 {
  text-align: left;
  margin-bottom: 60px;
}

.policy-container {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.policy-wrapper {
  margin-top: 60px;
}

.policy-wrapper h3 {
  font-size: 2.5rem;
  margin-top: 40px;
}

.policy-wrapper p {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 24px;
}

.policy-title {
  text-align: center;
  font-size: 3.125rem;
  margin-bottom: 22px;
}

.policy-subtitle {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 24px;
}

.policy-subtitle a {
  color: #005195;
  text-decoration: underline;
}

.text-title {
  font-size: 2.5rem;
  margin-top: 40px;
}

.text {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 24px;
}

.text a {
  color: #005195;
  text-decoration: underline;
}

.text:last-child {
  margin-bottom: 100px;
}

.text-subtitle {
  margin-top: 20px;
  font-size: 1.5rem;
}

.text2 {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 24px;
}

.success-wrapper {
  padding: 40px 30px;
  font-size: 16px;
  background: #e8f9ec;
  border: 1px solid #e2ece4;
  position: relative;
  margin-bottom: 2rem;
  border-radius: 0.25rem;
}

.success-wrapper i {
  font-size: 130px;
  display: inline-block;
  color: #43b118;
}

.success-wrapper h1 {
  margin: 15px auto;
  color: #43b118;
  font-size: 38px;
  font-weight: 900;
  letter-spacing: 1px;
}

.success-wrapper p {
  color: #465c66;
  font-weight: 300;
  font-size: 19px;
  margin-bottom: 0;
}

.success-wrapper strong {
  font-weight: 700;
}

@media (max-width: 1500px) {
  .hero-section__content img {
    top: 7%;
  }
}

@media (max-width: 1400px) {
  .hero-section__content {
    grid-template-columns: 0.5fr 1fr;
  }
}

@media (max-width: 1350px) {
  h2 {
    font-size: 35px;
    line-height: 110%;
  }

  .hero-section__content h1 {
    font-size: 35px;
    line-height: 110%;
  }

  .hero-section__content p {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .hero-section__content {
    grid-template-columns: 0.4fr 1fr;
    padding: 25px 0 0 75px;
  }
}

@media (max-width: 1212px) {
  .hero-container {
    padding: 0 50px;
  }

  .container {
    padding: 0 50px;
  }

  .fix-container {
    padding: 0;
  }

  .footer-container {
    padding: 0 100px;
  }

  .header {
    padding: 50px 50px;
  }

  .header {
    padding: 50px 50px;
  }

  .header__menu li {
    margin-right: 20px;
  }

  .header__menu a {
    font-size: 15px;
  }

  .header__logo {
    max-width: 140px;
  }

  .header .button-wrapper {
    gap: 20px;
  }

  .header .button-wrapper img {
    max-width: 80px;
  }

  .footer__menu {
    grid-gap: 100px;
  }
}

@media (max-width: 1200px) {
  .hero-section__content {
    grid-template-columns: repeat(2, 1fr);
    padding: 25px 25px 25px 50px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-section__content img {
    top: 5%;
  }

  .grid-section {
    margin-top: 150px;
  }
}

@media (max-width: 1100px) {
  .spoilers-block__body {
    max-width: 600px;
  }
}

@media (max-width: 1050px) {
  .fix-container {
    padding: 0 50px;
  }

  .blue-section__item {
    padding: 44px 25px 44px 20px;
  }
}

@media (max-width: 992px) {
  section {
    margin: 100px 0 0 0;
  }

  .hero-container {
    padding: 0 30px;
  }

  .container {
    padding: 0 50px;
  }

  .footer {
    margin: 100px 0 0 0;
  }

  .footer__menu {
    grid-gap: 40px;
  }

  .grid-section__grid {
    grid-gap: 30px;
    padding: 0;
  }

  .mac-section h2 {
    margin: 0 auto 60px auto;
  }

  .mac-section__item {
    grid-gap: 30px;
    grid-template-columns: 1fr 0.7fr;
    margin-bottom: 100px;
  }

  .mac-section__right p {
    margin-top: 20px;
  }

  .option-section__left p {
    margin-top: 20px;
  }

  .blue-section__grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (max-width: 992px) and (max-width: 992px) {
  .blue-section__item:nth-child(1) {
    grid-column: 1/5;
  }

  .blue-section__item:nth-child(2) {
    grid-column: 5/9;
  }

  .blue-section__item:nth-child(3) {
    grid-column: 3/7;
    grid-row: 2/2;
  }
}

@media (max-width: 992px) and (max-width: 600px) {
  .blue-section__item:nth-child(1) {
    grid-column: 1/1;
  }

  .blue-section__item:nth-child(2) {
    grid-column: 1/1;
  }

  .blue-section__item:nth-child(3) {
    grid-column: 1/1;
  }
}

@media (max-width: 870px) {
  .spoilers-block__body {
    max-width: 450px;
  }
}

@media (max-width: 850px) {
  .mac-section__item {
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .spoilers-block__title::before {
    right: 9px;
  }
  .header {
    padding: 50px 30px;
  }
  .spoilers-block__item {
    margin: 0px 0px 20px 0px;
  }

  .spoilers-block__title {
    font-size: 22px;
    padding: 20px 70px 20px 0;
  }

  .spoilers-block__body {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 400px;
  }

  .container {
    padding: 0 30px;
  }

  .small-container {
    padding: 0 30px;
    width: 100%;
  }

  .footer-container {
    padding: 0 50px;
  }

  .header__menu {
    display: block;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 100px 30px 30px 30px;
    left: -100%;
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    transition: left 0.3s ease;
    -webkit-transform: translateY(var(--header-height));
    -ms-transform: translateY(var(--header-height));
    transform: translateY(var(--header-height));
  }

  .header__menu > li {
    padding: 0;
    margin-bottom: 30px;
  }

  .header__menu > li:last-child {
    margin-bottom: 0;
  }

  .header__menu a {
    color: #fff;
    font-size: 2rem;
  }

  .header__menu.active {
    left: 0;
  }

  .header .button-wrapper img {
    display: none;
  }

  .icon-menu {
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    cursor: pointer;
    z-index: 5;
  }

  .icon-menu span,
  .icon-menu:before,
  .icon-menu:after {
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    top: calc(50% - 1px);
    left: 0px;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--text-color);
  }

  .icon-menu:before,
  .icon-menu:after {
    content: ' ';
  }

  .icon-menu:before {
    top: 0px;
  }

  .icon-menu:after {
    top: auto;
    bottom: 0px;
  }

  .icon-menu.active span {
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  .icon-menu.active:before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: calc(50% - 1px);
  }

  .icon-menu.active:after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: calc(50% - 1px);
  }

  .footer__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 50px;
    text-align: center;
  }

  .footer__item b {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer__item ul {
    margin-top: 20px;
  }

  .footer__item ul a li {
    margin-bottom: 10px;
  }

  .hero-section__content {
    grid-template-columns: repeat(1, 1fr);
    padding: 25px 25px 50px 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-gap: 50px;
    max-height: 800px;
  }

  .hero-section__download-button {
    bottom: -8%;
  }

  .grid-section__grid {
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 50px;
  }

  .grid-section__item:nth-child(1) {
    grid-column: 1/5;
  }

  .grid-section__item:nth-child(2) {
    grid-column: 5/9;
  }

  .grid-section__item:nth-child(3) {
    grid-column: 3/7;
    grid-row: 2/2;
  }

  .mac-section__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 70px;
  }

  .mac-section__item img {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }

  .mac-section__right {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media (max-width: 700px) {
  .option-section__item {
    gap: 50px;
  }
}

@media (max-width: 650px) {
  .hero-section__download-button {
    bottom: -12%;
  }
}

@media (max-width: 600px) {
  .blue-section__grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    text-align: center;
  }

  .blue-section__item img {
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .spoilers-block__title {
    font-size: 18px;
  }

  .footer-container {
    padding: 0 30px;
  }

  h2 {
    font-size: 30px;
    line-height: 100%;
  }

  .footer__copyright {
    padding-bottom: 40px;
  }

  .grid-section__grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-section__item:nth-child(1) {
    grid-column: 1/1;
  }

  .grid-section__item:nth-child(2) {
    grid-column: 1/1;
  }

  .grid-section__item:nth-child(3) {
    grid-column: 1/1;
  }

  .grid-section__item b {
    margin: 20px 0 15px 0;
  }

  .option-section__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 40px;
  }

  .option-section__left {
    text-align: center;
  }
}

.sb-main-padded {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ftr-complete-wrap {
  margin-bottom: 100px !important;
}

@media (max-width: 992px) {
  .header {
    padding: 0 20px !important;
    padding-bottom: 30px !important;
  }
  .header {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .header p {
    text-align: center;
  }
  .header a {
    text-align: center;
  }
  .header img {
    padding-left: 0;
  }
  .header__help {
    padding: 0;
  }
}

.checkout-error-section {
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
  max-width: 500px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkout-error-section a {
  font-weight: bold;
  color: #377dff;
  text-decoration: underline;
}

.checkout-error-section a:hover {
  text-decoration: none;
}

.checkout-error-section {
  min-height: calc(100vh - 104px);
  margin-top: 0;
  margin-bottom: 0;
  height: 100%;
}

.checkout-error-section h1 {
  font-size: 40px;
}

.order-wrap {
  margin-top: 80px;
}

.ftr {
  text-align: center;
}

.keyWrap {
  max-width: 750px;
  margin-right: auto;
  margin-left: auto;
}
